import React from "react";
import CustomerInfo from "../components/customers/CustomerInfo";
import CustomerBilling from "../components/customers/CustomerBilling";
import AdditionalInfo from "../components/customers/AdditionalInfo";
import UserAddress from "../components/customers/UserAddress";

const MiInformacion = () => {
  return (
    <div className="container" style={{ maxWidth: "1000px" }}>
      <h1>Mi Información</h1>
      <div className="card p-3 shadow-sm no-scale mb-2">
        <CustomerInfo />
      </div>

      <div className="row mb-2 p-3">
        <UserAddress />
        <CustomerBilling />
      </div>

      <div className="row mb-2 py-0 p-3">
        <AdditionalInfo />
      </div>
    </div>
  );
};

export default MiInformacion;
